<template>
    <CITFDatePicker
            :state="state"
            format="M/D/YYYY"
            :value="value"
            :disabled="disabled"
            :clearable="false"
            @input="$emit('input', $event)"
            range confirm />
</template>
<script>
import {Component, Vue, Prop} from 'vue-property-decorator';
import CITFDatePicker from '@/components/shared/CITFDatePicker.vue';

@Component({
    components: {
        CITFDatePicker
    }
})
export default class ProgramDateRange extends Vue {
    @Prop({type: Array}) value;
    @Prop({type: Boolean}) disabled;
    @Prop({type: Boolean}) state;
}
</script>
<style scoped>

</style>
