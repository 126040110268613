<template>
    <div>
        <!--WORKSHOP ALLOCATIONS-->
        <!--Workshop allocations are read-only in SCT-->
        <template>
            <strong>Workshop Allocations</strong>
        </template>
        <b-table-lite small
                      striped
                      hover
                      bordered
                      stacked="sm"
                      head-variant="dark"
                      :fields="workshopAllocationFields"
                      :items="workshopAllocations">
            <template v-slot:cell(type)="row">
                {{row.item.type}}
            </template>
            <template v-slot:cell(typeId)="row">
                {{row.item.typeId}}
            </template>
            <template v-slot:cell(year)="row">
                {{row.item.year}}
            </template>
            <template v-slot:cell(instanceAllocation)="row">
                {{row.item.instanceAllocation}}
            </template>
            <template v-slot:cell(initialAllocation)="row">
                {{row.item.initialAllocation}}
            </template>
            <template v-slot:cell(adjustedAllocation)="row">
                {{row.item.adjustedAllocation}}
            </template>
        </b-table-lite>
        <template v-if="!hasWorkshopAllocations">
            No workshop allocations exist
        </template>
        <br /><br />
        <!--WORKSHOP INSTANCE ALLOCATIONS-->
        <!--Workshop instance allocations are editable in SCT-->
        <template>
            <strong>Workshop Instance Allocations</strong>
        </template>
        <b-table-lite small
                      striped
                      hover
                      bordered
                      stacked="sm"
                      head-variant="dark"
                      :fields="workshopInstanceAllocationFields"
                      :items="workshopInstanceAllocations">
            <template v-slot:cell(type)="row">
                <template v-if="row.item.editMode">
                    <b-form-group :state="errorState(row.item.workspace, 'type')"
                                  invalid-feedback="Required">
                        <b-form-radio name="type-radio"
                                      v-model="row.item.workspace.allocationType"
                                      :value="councilType"
                                      size="sm">Council</b-form-radio>
                        <b-form-radio name="type-radio"
                                      v-model="row.item.workspace.allocationType"
                                      :value="localType"
                                      size="sm">Local</b-form-radio>
                    </b-form-group>
                </template>
                <template v-else>
                    {{row.item.type}}
                </template>
            </template>
            <template v-slot:cell(typeId)="row">
                <template v-if="row.item.editMode">
                    <b-form-group :state="errorState(row.item.workspace, 'typeId')"
                                  invalid-feedback="Required">
                        <b-select :options="typeIdOptions(row.item.workspace)"
                                  v-model="row.item.workspace.typeId"
                                  :state="errorState(row.item.workspace, 'typeId')"
                                  size="sm">
                            <template v-slot:first>
                                <option :value="null"> - </option>
                            </template>
                        </b-select>
                    </b-form-group>
                </template>
                <template v-else>
                    {{row.item.typeId}}
                </template>
            </template>
            <template v-slot:cell(instanceAllocation)="row">
                <template v-if="row.item.editMode">
                    <b-form-group :state="errorState(row.item.workspace, 'instanceAllocation')"
                                  invalid-feedback="Required">
                        <b-input placeholder="Instance Allocation"
                                 type="number"
                                 v-model="row.item.workspace.instanceAllocation"
                                 :state="errorState(row.item.workspace, 'instanceAllocation')"
                                 size="sm" />
                    </b-form-group>
                </template>
                <template v-else>
                    {{row.item.instanceAllocation}}
                </template>
            </template>
            <!--Per-Allocation Actions -->
            <template v-slot:cell(actions)="row"
                      v-if="canEdit">
                <b-button-group>
                    <b-button
                            @click="remove(row.item)"
                            variant="danger"
                            size="sm">Delete</b-button>
                    <b-button
                            v-if="!row.item.editMode"
                            @click="edit(row.item)"
                            variant="primary"
                            size="sm">Edit</b-button>
                    <b-button
                            v-if="row.item.editMode"
                            @click="cancel(row.item)"
                            variant="warning"
                            size="sm">Cancel Edit</b-button>
                    <b-button
                            v-if="row.item.editMode"
                            @click="save(row.item)"
                            variant="success"
                            :disabled="allocationErrors(row.item.workspace)"
                            size="sm">Save</b-button>
                </b-button-group>
            </template>
        </b-table-lite>
        <template v-if="!hasWorkshopInstanceAllocations">
            No workshop instance allocations exist
        </template>
        <br /><br />
        <b-button-group class="float-right">
            <b-button v-if="canEdit"
                      variant="dark"
                      @click="createNewAllocation">Create New Workshop Instance Allocation</b-button>
        </b-button-group>
        <!--NEW WORKSHOP INSTANCE ALLOCATION-->
        <b-modal :id="newAllocationModalId"
                 size="lg"
                 title="New Workshop Instance Allocation"
                 header-bg-variant="dark"
                 header-text-variant="white"
                 button-size="sm"
                 hide-footer
                 scrollable>
            <b-form>
                <b-form-group label="Type"
                              :state="errorState(newAllocation.workspace, 'type')"
                              invalid-feedback="Required">
                    <b-form-radio name="type-radio"
                                  v-model="newAllocation.workspace.allocationType"
                                  :value="councilType"
                                  :state="errorState(newAllocation.workspace, 'type')"
                                  size="sm">Council</b-form-radio>
                    <b-form-radio name="type-radio"
                                  v-model="newAllocation.workspace.allocationType"
                                  :value="localType"
                                  :state="errorState(newAllocation.workspace, 'type')"
                                  size="sm">Local</b-form-radio>
                </b-form-group>
                <b-form-group label="ID"
                              :state="errorState(newAllocation.workspace, 'typeId')"
                              invalid-feedback="Required">
                    <b-select :options="typeIdOptions(newAllocation.workspace)"
                              v-model="newAllocation.workspace.typeId"
                              :state="errorState(newAllocation.workspace, 'typeId')"
                              size="sm">
                        <template v-slot:first>
                            <option :value="null"> - </option>
                        </template>
                    </b-select>
                </b-form-group>
                <b-form-group label="Instance Allocation"
                              :state="errorState(newAllocation.workspace, 'instanceAllocation')"
                              invalid-feedback="Required">
                    <b-input placeholder="Instance Allocation"
                             type="number"
                             v-model="newAllocation.workspace.instanceAllocation"
                             :state="errorState(newAllocation.workspace, 'instanceAllocation')"
                             size="sm" />
                </b-form-group>
                <b-button-group class="float-right">
                    <b-button @click="cancelNewAllocation"
                              variant="warning"
                              size="sm">Cancel</b-button>
                    <b-button @click="saveNewAllocation"
                              variant="primary"
                              :disabled="allocationErrors(newAllocation.workspace)"
                              size="sm">Save</b-button>
                </b-button-group>
            </b-form>
        </b-modal>
    </div>
</template>


<script>
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
    import store from '@/store/store';
    import _ from 'underscore';
    import formatters from '@/util/formatters';
    import {toDate, addDays} from 'date-fns';
    import {sprintf} from "sprintf-js";
    import ProgramTimeRange from "./ProgramTimeRange";
    import SessionDetails from "./SessionDetails";
    import {WorkshopInstance} from "../../../model/session";
    import {errorToastOptions} from "../../../util/formatters";
    import {NULL_WORKSHOP_INSTANCE_ALLOCATION, Type} from "../../../model/allocation";

    @Component({
        components: {},
        asyncComputed: {
            workshopAllocations: {
                async get() {
                    if (WorkshopInstance.isValid(this.workshopInstance)) {
                        try {
                            const workshopId = this.workshopInstance.workshopId;
                            await store.dispatch('registration/loadWorkshopAllocations', workshopId);
                            const allocations = this.$store.getters['registration/workshopAllocations'](workshopId);
                            // console.log(allocations);
                            return allocations;
                        }
                        catch (error) {
                            this.$bvToast.toast(error.message, errorToastOptions);
                        }
                    }
                    else {
                        return [];
                    }
                }
            },
            workshopInstanceAllocations: {
                async get() {
                    if (WorkshopInstance.isValid(this.workshopInstance)) {
                        try {
                            const workshopInstanceId = this.workshopInstance.workshopInstanceId;
                            await store.dispatch('registration/loadWorkshopInstanceAllocations', workshopInstanceId);
                            const allocations = this.$store.getters['registration/workshopInstanceAllocations'](workshopInstanceId);
                            // console.log(allocations);
                            return allocations;
                        }
                        catch (error) {
                            this.$bvToast.toast(error.message, errorToastOptions);
                        }
                    }
                    else {
                        return [];
                    }
                }
            }
        },
        methods: {
            refreshAllocations() {
                //No need to refresh workshop allocations as they are display-only
                this.$asyncComputed.workshopInstanceAllocations.update();
            }
        }
    })

    export default class Allocations extends Vue {
        @Prop({type: [WorkshopInstance], default: null}) workshopInstance;
        newAllocation = NULL_WORKSHOP_INSTANCE_ALLOCATION.clone();

        resetNewAllocation() {
            this.newAllocation = NULL_WORKSHOP_INSTANCE_ALLOCATION.clone();
            this.newAllocation.workspace.workshopInstanceId = this.workshopInstance.workshopInstanceId;
        }

        get newAllocationModalId() {
            return 'new-allocation-modal';
        }

        cancelNewAllocation() {
            this.$bvModal.hide(this.newAllocationModalId);
            this.newAllocation.rollback();
        }

        async saveNewAllocation() {
            // console.log(this.newAllocation.workspace);
            try {
                await store.dispatch('registration/processWorkshopInstanceAllocation', this.newAllocation.workspace);
                this.newAllocation.rollback();
                await this.refreshAllocations();
                this.$bvModal.hide(this.newAllocationModalId);
            }
            catch (error) {
                return await this.$bvModal.msgBoxOk(error.message, {
                    title: 'Error',
                    size: 'sm',
                    buttonSize: 'sm',
                    centered: true
                });
            }
        }

        createNewAllocation() {
            this.resetNewAllocation();
            this.$bvModal.show(this.newAllocationModalId);
        }

        async remove(allocation) {
            // console.log(allocation);
            try {
                await store.dispatch('registration/deleteWorkshopInstanceAllocation', allocation);
                await this.refreshAllocations();
            }
            catch (error) {
                return await this.$bvModal.msgBoxOk(error.message, {
                    title: 'Error',
                    size: 'sm',
                    buttonSize: 'sm',
                    centered: true
                });
            }
        }

        edit(allocation) {
            allocation.editMode = true;
        }

        cancel(allocation) {
            allocation.rollback();
            this.editMode = false;
        }

        async save(allocation) {
            console.log(allocation);
            try {
                await store.dispatch('registration/processWorkshopInstanceAllocation', allocation.workspace);
                allocation.commit();
                this.editMode = false;
                await this.refreshAllocations();
            }
            catch (error) {
                return await this.$bvModal.msgBoxOk(error.message, {
                    title: 'Error',
                    size: 'sm',
                    buttonSize: 'sm',
                    centered: true
                });
            }
        }
        
        errorState(allocation, field) {
            return !allocation.errors[field];
        }

        allocationErrors(allocation) {
            const errors = _.any(allocation.fields, field => {
                const fieldError = allocation.errors[field];
                // console.log(sprintf('Field: %s Error: %s', field, fieldError));
                return fieldError;
            });
            return errors;
        }

        // get workshopAllocations() {
        //     const allocations = this.$store.getters['registration/workshopAllocations'](this.workshopInstance.workshopId);
        //     // console.log(allocations);
        //     return allocations || [];
        // }

        get hasWorkshopAllocations() {
            return !_.isEmpty(this.workshopAllocations);
        }

        get workshopAllocationFields() {
            const fields = [{
                key: 'type',
                label: 'Type'
            }, {
                key: 'typeId',
                label: 'ID'
            }, {
                key: 'year',
                label: 'Year'
            }, {
                key: 'instanceAllocation',
                label: 'Instance Allocation'
            }, {
                key: 'initialAllocation',
                label: 'Initial Allocation'
            }, {
                key: 'adjustedAllocation',
                label: 'Adjusted Allocation'
            }];
            return fields;
        }

        // get workshopInstanceAllocations() {
        //     const allocations = this.$store.getters['registration/workshopInstanceAllocations'](this.workshopInstance.workshopInstanceId);
        //     // console.log(allocations);
        //     return allocations || [];
        // }

        get hasWorkshopInstanceAllocations() {
            return !_.isEmpty(this.workshopInstanceAllocations);
        }

        get workshopInstanceAllocationFields() {
            const fields = [{
                key: 'type',
                label: 'Type'
            }, {
                key: 'typeId',
                label: 'ID'
            }, {
                key: 'instanceAllocation',
                label: 'Allocation'
            }];
            if (this.canEdit) {
                fields.push({
                    key: 'actions',
                    label: 'Actions'
                });
            }
            return fields;
        }

        //Type ID options served is based on type selection
        typeIdOptions(allocation) {
            // console.log(allocation);
            switch (allocation.type) {
                case Type.COUNCIL:
                    return this.councils;
                case Type.LOCAL:
                    return this.locals;
                case Type.INVALID:
                default:
                    return [];
            }
        }

        get councils() {
            const councils = _.groupBy(this.$store.getters['common/councils'], c => c.districtName);
            const formattedCouncils =  _.map(councils, (cs, district) => {
                return {
                    label: district,
                    options: _.map(cs, (c) => {
                        return {
                            value: c.displayId,
                            text: sprintf('%s (%s)', c.name, c.displayId)
                        };
                    })
                };
            });
            // formattedCouncils.unshift({
            //     label: '',
            //     options: [{
            //         value: null,
            //         text: '-'
            //     }]
            // });
            return formattedCouncils;
        }

        get locals() {
            const locals = _.groupBy(this.$store.getters['common/locals'], l => {
                return sprintf("%s - %s", l.districtName, l.councilName);
            });
            const formattedLocals =  _.map(locals, (ls, districtCouncil) => {
                return {
                    label: districtCouncil,
                    options: _.map(ls, (local) => {
                        return {
                            value: local.displayId,
                            text: sprintf("%s - %s, %s", local.displayId, local.city, local.state)
                        };
                    })
                };
            });
            return formattedLocals;
        }

        get councilType() {
            return Type.COUNCIL;
        }

        get localType() {
            return Type.LOCAL;
        }

        get canEdit() {
            //Allocations are editable by admin users for non-cancelled, non-past sessions
            return this.$store.getters['userSession/getUser'].isAnAdministrator() &&
                WorkshopInstance.isValid(this.workshopInstance) &&
                !this.workshopInstance.canceled &&
                !this.workshopInstance.hasEnded;
        }

        async mounted() {
            // console.log(this.workshopInstance);
            try {
                await store.dispatch('common/loadLocals');
                await store.dispatch('common/loadCouncils');
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }
    }
</script>


<style scoped>
    b-form-group.label {
        font-weight: bold;
    }
</style>