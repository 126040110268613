<template>
    <date-picker lang="en"
                 type="time"
                 @input="$emit('input', $event)"
                 :state="state"
                 :format="format"
                 :title="title"
                 :value="value"
                 :value-type="valueType"
                 :hour-step="hourStep"
                 :minute-step="minuteStep"
                 :show-hour="showHour"
                 :show-minute="showMinute"
                 :show-second="showSecond"
                 :range="range"
                 :range-separator="rangeSeparator"
                 :width="width"
                 :show-time-header="showTimeHeader"
                 :clearable="clearable"
                 :confirm="confirm"
                 :editable="editable"
                 :disabled="disabled"
                 :input-attr="inputAttr"
                 :append-to-body="appendToBody"
                 :placeholder="placeholder"
                 :time-picker-options="timePickerOptions"
                 input-class="form-control form-control-sm" >
        <template slot="calendar-icon" v-if="hideIcon">&nbsp;</template>
    </date-picker>
</template>

<!--                 TODO - make this implementation less ugly-->
<!--                 :show-status="showStatus"-->
<!--                 :class="inputClass"-->
<!--                 :default-class="defaultClass"-->

<script>
import {Vue, Component, Prop} from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import _ from "underscore";

@Component({
    components: {
        DatePicker
    }
})

export default class CITFTimePicker extends Vue {
    // @Prop({type: String, default: 'form-control'}) defaultClass;
    @Prop({type: Boolean, default: false}) showStatus;
    @Prop({type: Boolean, default: true}) state;
    @Prop({type: String, default: 'hh:mm A'}) format;  //display format for holding picker values when not expanded
    @Prop({type: String, default: ''}) title;
    @Prop({type: [Date, Array]}) value;
    @Prop({type: Date}) valueType;
    @Prop({type: Number, default: 1}) hourStep;
    @Prop({type: Number, default: 5}) minuteStep;
    @Prop({type: Boolean, default: true}) showHour;
    @Prop({type: Boolean, default: true}) showMinute;
    @Prop({type: Boolean, default: false}) showSecond;
    @Prop({type: Boolean, default: true}) use12h;
    @Prop({type: Boolean, default: false}) range;
    @Prop({type: String, default: ' → '}) rangeSeparator;
    @Prop({type: Number, default: 210}) width;  //based on CITFDatePicker
    @Prop({type: Boolean, default: false}) showTimeHeader;
    @Prop({type: Boolean, default: true}) clearable;
    @Prop({type: Boolean, default: false}) confirm;
    @Prop({type: Boolean, default: true}) editable;
    @Prop({type: Boolean, default: false}) disabled;
    @Prop({type: Object}) inputAttr;
    @Prop({type: Boolean, default: true}) appendToBody;
    @Prop({type: Boolean, default: false}) hideIcon;
    @Prop({type: String}) placeholder;
    @Prop({type: Object, default: function() {
            return {
                start: '04:00',
                step: '00:15',
                end: '23:30',
                format: 'hh:mm A'  //display format within the picker when expanded
            };
        }}) timePickerOptions;

    get isValid() {
        return _.isEmpty(this.value) || this.value.length !== 2 || _.isNull(this.value[0]) || _.isNull(this.value[1]);
    }

    get inputClass() {
        if (!this.showStatus) {
            return this.defaultClass;
        }
        else {
            return this.defaultClass + ' ' + (this.isValid ? 'is-valid' : 'is-invalid');
        }
    }

    keyup() {
        this.$emit('state', this.isValid);
    }
}
</script>
<style scoped xml:lang="scss">
    @import '~vue2-datepicker/index.css';
</style>
