<template>
    <CITFTimePicker
            :show-status=true
            :state="state"
            :value="value"
            :disabled="disabled"
            :clearable=false
            @input="$emit('input', $event)"
            range
            confirm
            placeholder="Select Time Range">
        <template slot="header" v-if="false"></template>
    </CITFTimePicker>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import CITFTimePicker from '@/components/shared/CITFTimePicker.vue';

    @Component({
        components: {
            CITFTimePicker
        }
    })

    export default class ProgramTimeRange extends Vue {
        @Prop({type: Array}) value;
        @Prop({type: Boolean}) disabled;
        @Prop({type: Boolean}) state;
    }
</script>